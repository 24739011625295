import React, {Component} from 'react';
import './InterestsImage.css';


class InterestsImage extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:this.props.isShown,
			className:'interestsImage'
		};
		
		this.imagePath = this.props.imagePath;
		this.elem = null;
		
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	/*componentWillMount()
	{
		
	}*/
	
	componentDidMount()
	{
		var sizer = 'LG';
		
		if (window.screen.width > 750) // && this.state.landscape === false
		{
			//this.DELIMITER = -650;
		}
		else if (window.screen.width > 1000)
		{
			//this.DELIMITER = -350;
		}
		else
		{
			sizer = 'SM';
		}
		
		var sizeSplitter = this.imagePath.split('SIZE');
		
		this.elem.style.backgroundImage = 'url(./'+sizeSplitter[0]+sizer+sizeSplitter[1]+')';
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.isShown !== this.state.isShown)
		{
			if (newProps.isShown)
			{
				this.show();
			}
			else
			{
				this.hide();
			}
		}
	}
	
	show()
	{
		this.setState(
		{
			isShown:true,
			className:'interestsImage show'
		});
	}
	
	hide()
	{
		this.setState(
		{
			isShown:false,
			className:'interestsImage'
		});
	}
	
	compileJsx()
	{
		var jsx = <div>
					<div 
						className={this.state.className} 
						ref={(elem) => this.elem = elem}
					>
					</div>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div className={'interestsImageContainer'}>
				{jsx}
			</div>
		);
	}
}

export default InterestsImage;