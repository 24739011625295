import React, {Component} from 'react';
import './IntroductionBlurb.css';


class IntroductionBlurb extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:this.props.isShown,
			className:'introductionBlurb'
		};
		
		this.dataA = this.props.dataA;
		
		this.show = this.show.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	/*componentWillMount()
	{
		
	}*/
	
	componentDidMount()
	{
		/*
		//var h = window.getComputedStyle(this.elem).height;
		var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		//console.log(y);
		this.callback(this.itemNum,y);
		*/
		
		//this.elem.style.backgroundImage = 'url(./'+this.imagePath+')';
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.isShown !== this.state.isShown)
		{
			if (newProps.isShown)
			{
				this.show();
			}
		}
	}
	
	show()
	{
		this.setState(
		{
			isShown:true,
			className:'introductionBlurb show'
		});
	}
	
	compileJsx()
	{
		var paragraphSplit = this.dataA[1].split('\n');
		var paragraphJsx = [];
		for (var i=0;i<paragraphSplit.length;i++)
		{
			var pJsx = <p key={'blurbP'+i}>
						{paragraphSplit[i]}<br/>
					   </p>;
			paragraphJsx.push(pJsx);
		}
		
		//<div dangerouslySetInnerHTML={{ __html: dataA[1] }} />
		
		var jsx = <div>
					<div className={this.state.className}>
						<p className={'introductionBlurbTitle'}>
							{this.dataA[0]}
						</p>
						<div>
							{paragraphJsx}
						</div>
					</div>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div className={'introductionBlurbContainer'}>
				{jsx}
			</div>
		);
	}
}

export default IntroductionBlurb;