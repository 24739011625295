import React, {Component} from 'react';
import './MenuManager.css';
import Burger from './Burger';
import Menu from './Menu';


class MenuManager extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			showMenu:false,
			currPageNum:this.props.currPageNum,
			callRemoval:false
		};
		
		this.dataA = this.props.dataA;
		this.callback = this.props.callback;
		
		//this.currPageNum = 0;
		
		this.burgerClicked = this.burgerClicked.bind(this);
		this.sectionChosen = this.sectionChosen.bind(this);
		this.removeMenu = this.removeMenu.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		if (window.screen.width > 750)
		{
			this.setState(
			{
				showMenu:true
			});
		}
	}
	
	componentDidMount()
	{
		//console.log(this.dataA);
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.currPageNum !== this.state.currPageNum)
		{
			this.setState({
				currPageNum:newProps.currPageNum
			});
		}
	}
	
	burgerClicked()
	{
		var show = this.state.showMenu ? false : true;
		
		if (show === true)
		{
			this.setState(
			{
				showMenu:true
			});
		}
		else
		{
			this.setState(
			{
				callRemoval:true
			});
		}
	}
	
	sectionChosen(indexNum)
	{
		this.currPageNum = indexNum;
		
		this.callback(indexNum);
		
		if (window.screen.width < 750)
		{
			this.setState(
			{
				callRemoval:true
			});
		}
	}
	
	removeMenu()
	{
		this.setState(
		{
			showMenu:false,
			callRemoval:false
		});
	}
	
	compileJsx()
	{
		var menuTitlesA = [];
		for (var i=0;i<this.dataA.length;i++)
		{
			menuTitlesA.push(this.dataA[i].TITLE);
		}
		
		var jsx = <div>
					{this.state.showMenu ? 
						<Menu dataA={menuTitlesA} 
						currPageNum={this.state.currPageNum}
						callRemoval={this.state.callRemoval} 
						sectionChosenCallback={this.sectionChosen}
						removalCallback={this.removeMenu}
						/> : null}
					<Burger
						callback={this.burgerClicked}
					/>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div id={'menuManagerContainer'}>
				{jsx}
			</div>
		);
	}
}

export default MenuManager;