import React, {Component} from 'react';
import './CaseStudiesBlurb.css';


class CaseStudiesBlurb extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:this.props.isShown,
			className:'caseStudiesBlurb'
		};
		
		this.title = this.props.title;
		this.blurb = this.props.blurb;
		
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	/*componentWillMount()
	{
		
	}*/
	
	componentDidMount()
	{
		/*
		//var h = window.getComputedStyle(this.elem).height;
		var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		//console.log(y);
		this.callback(this.itemNum,y);
		*/
		
		//this.elem.style.backgroundImage = 'url(./'+this.imagePath+')';
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.isShown !== this.state.isShown)
		{
			if (newProps.isShown)
			{
				this.show();
			}
			else
			{
				this.hide();
			}
		}
	}
	
	show()
	{
		this.setState(
		{
			isShown:true,
			className:'caseStudiesBlurb show'
		});
	}
	
	hide()
	{
		this.setState(
		{
			isShown:false,
			className:'caseStudiesBlurb'
		});
	}
	
	compileJsx()
	{
		var jsx = <div>
					<div className={this.state.className}>
						<span className={'caseStudiesTitle'}>
							{this.title}
						</span>
						
						<p>
							{this.blurb}
						</p>
					</div>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div className={'caseStudiesBlurbContainer'}>
				{jsx}
			</div>
		);
	}
}

export default CaseStudiesBlurb;