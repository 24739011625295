import React, {Component} from 'react';
import './Button.css';




class Button extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:true,
			className:''
		};
		
		this.blurb = this.props.blurb;
		this.link = this.props.link;
		
		this.elem = null;
		
		
		this.openWindow = this.openWindow.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		
	}
	
	componentDidMount()
	{
		
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		/*
		if (newProps.isShown !== this.state.isShown)
		{
			if (newProps.isShown)
			{
				this.show();
			}
			else
			{
				this.hide();
			}
		}
		*/
		
		/*if (newProps.title !== this.state.title)
		{
			this.setState(
			{
				title:newProps.title
			});
		}*/
	}
	
	openWindow()
	{
		//window.open(this.link);
	}
	
	compileJsx()
	{
		var jsx = <div className={'button'}>
					{this.blurb}
				  </div>
		
		return jsx;
	}
	
	/* 
									 onClick={this.openWindow}
				
				
				
	
	*/
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<a href={this.link} target='_blank'>
			<div className={'buttonContainer'} ref={(elem) => this.elem = elem}>
				{jsx}
			</div>
			</a>
		);
	}
}

export default Button;