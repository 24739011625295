import React, {Component} from 'react';
import './InterestsBlurb.css';


class InterestsBlurb extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:this.props.isShown,
			className:'interestsBlurb'
		};
		
		this.title = this.props.title;
		this.blurb = this.props.blurb;
		this.linkDetails = this.props.linkDetails;
		
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	/*componentWillMount()
	{
		
	}*/
	
	componentDidMount()
	{
		/*
		//var h = window.getComputedStyle(this.elem).height;
		var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		//console.log(y);
		this.callback(this.itemNum,y);
		*/
		
		//this.elem.style.backgroundImage = 'url(./'+this.imagePath+')';
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.isShown !== this.state.isShown)
		{
			if (newProps.isShown)
			{
				this.show();
			}
			else
			{
				this.hide();
			}
		}
	}
	
	show()
	{
		this.setState(
		{
			isShown:true,
			className:'interestsBlurb show'
		});
	}
	
	hide()
	{
		this.setState(
		{
			isShown:false,
			className:'interestsBlurb'
		});
	}
	
	compileJsx()
	{
		var paragraphSplit = this.blurb.split('\n');
		var paragraphJsx = [];
		for (var i=0;i<paragraphSplit.length;i++)
		{
			var pJsx = <p key={'interestsBlurbP'+i}>
						{paragraphSplit[i]}<br/>
					   </p>;
			paragraphJsx.push(pJsx);
		}
		
		var detailsJsx = [];
		
		if (this.linkDetails !== undefined)
		{
			detailsJsx = <p className={'interestsDetailsLink'}>
						<a href={this.linkDetails.LINK_PATH} target={'_blank'} key={this.linkDetails.LINK_BLURB}>{this.linkDetails.LINK_BLURB}</a>
					</p>
		}
		
		/*
		for (i=0;i<this.dataA.DETAILS_A.length;i++)
		{
			var dJsx;
			if (i === 1)
			{
				dJsx = <p key={'detailsA'+i}>
						<a href={'http://'+this.dataA.LIVE_LINK} target={'_blank'} key={this.dataA.TITLE}>{this.dataA.DETAILS_A[i]}</a>
					   </p>
			}
			else if (i === 2)
			{
				dJsx = <p key={'detailsA'+i}>
						<a href={'http://'+this.dataA.GIT_LINK} target={'_blank'} key={this.dataA.TITLE}>{this.dataA.DETAILS_A[i]}</a>
					   </p>
			}
			else
			{
				dJsx = <p key={'detailsA'+i}>
						{this.dataA.DETAILS_A[i]}
					  </p>;
			}
			*/
		
		var jsx = <div>
					<div className={this.state.className}>
						<span className={'interestsTitle'}>
							{this.title}
						</span>
						
						<div>
							{paragraphJsx}
							<br/>
							{detailsJsx}
						</div>
					</div>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div className={'interestsBlurbContainer'}>
				{jsx}
			</div>
		);
	}
}

export default InterestsBlurb;