import React, {Component} from 'react';
import './Projects.css';
import ProjectManager from './ProjectManager';


class Projects extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			currY:this.props.currY,
			currNum:0,
			landscape:this.props.landscape
		};
		
		this.dataA = this.props.dataA;
		this.currY = this.props.currY;
		this.coordIndex = this.props.coordIndex;
		this.callback = this.props.callback;
		this.DELIMITER = -300;
		this.elem = null;
		this.coordsA = [];
		
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		
	}
	
	componentDidMount()
	{
		//var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		//this.callback(this.coordIndex,y);
		
	}
	
	componentDidUpdate()
	{
		//var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		//this.callback(this.coordIndex,y);
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.currY !== this.state.currY)
		{
			this.setState(
			{
				currY:newProps.currY
			});
		}
		
	}
	
	
	//callback={this.setCoordsA}
	compileJsx()
	{
		var jsx = <div>
					<ProjectManager 
						coordIndex={this.coordIndex}
						dataA={this.dataA.ITEMS}
						currY={this.state.currY}
						
					/>
				  </div>
		
		return jsx;
	}
	
	/*<div id={'agileBlurbContainer'}>
					<p className={'agileBlurbHeader'}>
						{this.dataA.AGILE_BLURB.HEADER}
					</p>
					<p className={'agileBlurb'}>
						{this.dataA.AGILE_BLURB.BLURB}
					</p>
				</div>*/
	
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div id={'projectsContainer'} ref={(elem) => this.elem = elem}>
				<p className={'projectsMainHeaderText'}>
					{this.dataA.HEADER_TITLE}
				</p>
				<p className={'projectsMainBlurbText'}>
					{this.dataA.HEADER_BLURB}
				</p>
				{jsx}
				
			</div>
		);
	}
}

export default Projects;// JavaScript Document