import React, {Component} from 'react';
import './CaseStudyButtonManager.css';

import Button from './Button';




class CaseStudyButtonManager extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:true,
			className:''
		};
		
		this.buttonsA = this.props.buttonsA;
		
		this.elem = null;
		
		
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		
	}
	
	componentDidMount()
	{
		
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		/*
		if (newProps.isShown !== this.state.isShown)
		{
			if (newProps.isShown)
			{
				this.show();
			}
			else
			{
				this.hide();
			}
		}
		*/
		
		/*if (newProps.title !== this.state.title)
		{
			this.setState(
			{
				title:newProps.title
			});
		}*/
	}
	
	compileJsx()
	{
		var jsxA = [];
		
		for (var i=0; i<this.buttonsA.length; i++)
		{
			var b = <Button
						key={'button'+i}
						blurb={this.buttonsA[i].BLURB}
						link={this.buttonsA[i].LINK}
					/>
			
			jsxA.push(b);
		}
		
		
		return jsxA;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div className={'caseStudyButtonManagerContainer'} ref={(elem) => this.elem = elem}>
				{jsx}
			</div>
		);
	}
}

export default CaseStudyButtonManager;