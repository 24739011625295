import React, {Component} from 'react';
import './IntroductionTitle.css';


class IntroductionTitle extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:this.props.isShown,
			classNameName:'introductionName',
			classNameTitle:'introductionTitle',
			loaded:false
		};
		
		this.elem = null;
		this.elemY = this.props.elemY;
		
		this.name = this.props.name;
		this.title = this.props.title;
		
		this.show = this.show.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	/*componentWillMount()
	{
		
	}*/
	
	componentDidMount()
	{
		/*
		//var h = window.getComputedStyle(this.elem).height;
		var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		//console.log(y);
		this.callback(this.itemNum,y);
		*/
		
		this.elemTop = (window.getComputedStyle?
    window.getComputedStyle(this.elem, null).getPropertyValue('top') :
    this.elem.currentStyle? this.elem.currentStyle.top : '0'
);
		
		//console.log(this.elemtop);
		
		
		
		if (window.screen.width > 500) //&& this.state.landscape === false
		{
			this.elemY = (this.elem.getBoundingClientRect().top - 1100) + window.scrollY;
		}
		else
		{
			this.elemY = (this.elem.getBoundingClientRect().top - 600) + window.scrollY;
		}
		
		
		//var initialT = this.elem.style.top;
		//console.log(this.elemTop);
		
		
		//console.log(this.elemY);
		
		
		//this.elemTop = parseInt(this.elemTop);
		//console.log(this.elemTop);
		
		//console.log(this.elemY);
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.isShown !== this.state.isShown)
		{
			if (newProps.isShown)
			{
				this.show();
			}
		}
		
		
		
		if (newProps.currY > this.elemY)
		//else
		{
			//console.log(this.elemY - newProps.currY);
			var y = (this.elemY - newProps.currY) *.5;
			//this.elem.style.transition = "none";
			this.elem.style.top = y+"px";
		}
		
		
		
		
		if (newProps.loaded !== this.state.loaded)
		{
			if (window.screen.width > 500) //&& this.state.landscape === false
			{
				this.elemY = (this.elem.getBoundingClientRect().top - 1100) + window.scrollY;
			}
			else
			{
				this.elemY = (this.elem.getBoundingClientRect().top - 600) + window.scrollY;
			}
			
			this.setState(
			{
				loaded:newProps.loaded
			});
		}
	}
	
	show()
	{
		this.setState(
		{
			isShown:true,
			classNameName:'introductionName show',
			classNameTitle:'introductionTitle show'
		});
	}
	
	compileJsx()
	{
		var jsx = <div>
					<div className={this.state.classNameName}>
						<p>
							{this.name}
						</p>
					</div>
					<div className={this.state.classNameTitle}>
						<p >
							{this.title}
						</p>
					</div>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('REND');
		var jsx = this.compileJsx();
		
		return(
			<div className={'introductionTitleContainer'} ref={(elem) => this.elem = elem}>
				{jsx}
			</div>
		);
	}
}

export default IntroductionTitle;