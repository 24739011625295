import React, {Component} from 'react';
import './Base.css';
//import data from './data/json.json';

import IntroductionBase from './views/home/IntroductionBase';
import UXBase from './views/ux/UXBase';
import DevBase from './views/dev/DevBase';
import AboutBase from './views/about/AboutBase';
import Process from './views/process/Process';
import MenuManager from './views/menu/MenuManager';

import Static from './views/utilities/static/Static';
import PreLoader from './views/utilities/preloader/PreLoader';


//import ProcessManager from './views/process/ProcessManager';
//import SkillsManager from './views/skills/SkillsManager';
//import ProjectManager from './views/projects/ProjectManager';
//import MenuManager from './views/menu/MenuManager';


class Base extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			jDataA:this.props.jDataA,
			currY:0,
			currPageNum:0,
			loaded:false,
			landscape:false
		};
		
		this.timer = null;
		this.coordsA = [];
		this.scrollToNum = 0;
		
		this.elem = null;
		//this.loadingInterval = null;		// REAL ONE, not used
		//this.loadingTimeout = null;			// FAKE ONE, used
		this.preloader = this.preloader.bind(this);
		
		
		this.opacityTimeout = null;
		this.updateOpacity = this.updateOpacity.bind(this);
		
		
		this.setCoordsA = this.setCoordsA.bind(this);
		this.changeSection = this.changeSection.bind(this);
		this.startScroller = this.startScroller.bind(this);
		this.stopScroller = this.stopScroller.bind(this);
		this.scroller = this.scroller.bind(this);
		this.checkScroll = this.checkScroll.bind(this);
		this.checkWindow = this.checkWindow.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		this.checkWindow();
		
		
		
		
		// PRELOADER
		//var container = document.getElementById('reactContainer');
		//console.log(container.getBoundingClientRect().height);
		//this.loadingInterval = setInterval(this.preloader,10);
		//this.loadingTimeout = setTimeout(this.preloader,2000);
	}
	
	componentDidMount()
	{
		var self = this;
		window.onscroll = function (e)
		{
			//console.log(window.pageYOffset);
			self.checkScroll(window.pageYOffset);
		}
		
		window.onresize = function(e)
		{
			self.checkWindow();
		}
		
		
		
		this.opacityTimeout = setTimeout(this.updateOpacity,400);
		
		
		// PRELOADER
		//console.log((this.elem.getBoundingClientRect().height) + window.scrollY);
		
		
	}
		// PRELOADER
	preloader()
	{
		//var container = document.getElementById('reactContainer');
		//console.log(container.getBoundingClientRect().height);
		
		//console.log('LOADED!');
		//this.changeSection(0);
		
		//this.loadingTimeout = null;
		this.setState({
			loaded:true
		});
	}
	
	updateOpacity()
	{
		this.elem.style.opacity = 1;
	}
	
	checkScroll(y)
	{
		//console.log(y);
		
		
		
		if (y > this.coordsA[this.state.currPageNum+1] && this.state.currPageNum < this.coordsA.length)
		{
			var pageNum = this.state.currPageNum + 1;
			this.setState(
			{
				currY:y,
				currPageNum:pageNum
			});
		}
		else if (y < this.coordsA[this.state.currPageNum]-300 && this.state.currPageNum > 0)
		{
			var pageNum = this.state.currPageNum - 1;
			this.setState(
			{
				currY:y,
				currPageNum:pageNum
			});
		}
		else 
		{
			this.setState(
			{
				currY:y
			});
		}
		
		
		
		
		/*
		var arr = [...this.state.itemsShownA];
		
		
		if (y >= this.yCoordsA[this.currShownNum])
		{
			arr[this.currShownNum] = true;
			this.currShownNum++;
		}
		else if (this.currShownNum >= 1 && y < this.yCoordsA[this.currShownNum-1])
		{
			arr[this.currShownNum-1] = false;
			this.currShownNum--;
		}
		else
		{
			return;
		}
		
		this.setState(
		{
			itemsShownA:arr
		});
		*/
	}
	
	checkWindow()
	{
		var landscape = false;
		if (window.screen.width > window.screen.height)
		{
			landscape = true;
		}
		
		this.setState({
			landscape:landscape
		});
	}
	
	changeSection(indexNum)
	{
		//console.log(indexNum);
		
		//window.location.href = this.state.jDataA.MENU[indexNum].LINK_PATH;
		
		this.scrollToNum = indexNum;
		this.startScroller();
	}
	
	startScroller()
	{
		if (this.timer === null)
		{
			this.timer = setInterval(this.scroller,10);
		}
	}
	
	stopScroller()
	{
		clearInterval(this.timer);
		this.timer = null;
		
		this.setState(
		{
			currPageNum:this.scrollToNum
		});
	}
	
	scroller()
	{
		var body = document.body;
		var html = document.documentElement;
		
		var dist;
		if (body.scrollTop)
		{
			dist = this.coordsA[this.scrollToNum] - body.scrollTop;
		}
		else
		{
			dist = this.coordsA[this.scrollToNum] - html.scrollTop;
		}
		
		var speed = dist * .2;
		if (speed < 0 && speed > -1)
		{
			speed = -1;
		}
		else if (speed > 0 && speed < 1)
		{
			speed = 1;
		}
		
		if ((body.scrollTop >= this.coordsA[this.scrollToNum]-1 && body.scrollTop <= this.coordsA[this.scrollToNum]+1) || (html.scrollTop >= this.coordsA[this.scrollToNum]-1 && html.scrollTop <= this.coordsA[this.scrollToNum]+1))
		{
			body.scrollTop = this.coordsA[this.scrollToNum]+1; // recently added to dynamically update Menu
			html.scrollTop = this.coordsA[this.scrollToNum]+1; // recently added to dynamically update Menu
			this.stopScroller();
		}
		else
		{
			body.scrollTop += speed;
			html.scrollTop += speed;
		}
	}
	
	setCoordsA(elemNum,y)
	{
		
		//console.log(elemNum,y);
		
		if (window.screen.width > 750 && elemNum > 0)
		{
			y -= 22;
		}
		
		this.coordsA[elemNum] = y;
	}
	
	/*
	
					  
					<ProcessManager 
						coordIndex={1}
						dataA={this.state.jDataA.PROCESS}
						currY={this.state.currY}
						callback={this.setCoordsA}
						landscape={this.state.landscape}
					/>
					<SkillsManager 
						coordIndex={2}
						dataA={this.state.jDataA.SKILLS}
						currY={this.state.currY}
						callback={this.setCoordsA}
						landscape={this.state.landscape}
					/>
					<ProjectManager 
						coordIndex={3}
						dataA={this.state.jDataA.PROJECTS}
						currY={this.state.currY}
						callback={this.setCoordsA}
						landscape={this.state.landscape}
					/>
					<MenuManager
						callback={this.changeSection}
					/>
					
					
					
					<RandomBlurbHTML
						dataA={this.state.jDataA.RANDOM_BLURB_HTML}
						currY={this.state.currY}
					/>
					
					
					
					
					
					
					
					*/
	
	
	compileJsx()
	{
		var jsx = <div>
							
					<PreLoader
						scrollTopCallback={this.changeSection}
						callback={this.preloader}
					/>
							
							
					<IntroductionBase 
						coordIndex={0}
						dataA={this.state.jDataA.HOME}
						currY={this.state.currY}
						callback={this.setCoordsA}
						loaded={this.state.loaded}
					/>
					<UXBase 
						coordIndex={1}
						dataA={this.state.jDataA.UX}
						currY={this.state.currY}
						callback={this.setCoordsA}
						loaded={this.state.loaded}
					/>
					<DevBase 
						coordIndex={2}
						dataA={this.state.jDataA.DEV}
						currY={this.state.currY}
						callback={this.setCoordsA}
						loaded={this.state.loaded}
					/>
					<AboutBase 
						coordIndex={3}
						dataA={this.state.jDataA.ABOUT}
						currY={this.state.currY}
						callback={this.setCoordsA}
						loaded={this.state.loaded}
					/>
					<Process 
						dataA={this.state.jDataA.PROCESS}
						currY={this.state.currY}
					/>
					<div className={'bottomSpacer'}></div>
					<Static
						imagePath={this.state.jDataA.STATIC_IMG_PATH}
					/>
					<Static
						imagePath={this.state.jDataA.STATIC_IMG_PATH}
					/>
					<Static
						imagePath={this.state.jDataA.STATIC_IMG_PATH}
					/>
					<Static
						imagePath={this.state.jDataA.STATIC_IMG_PATH}
					/>
					<Static
						imagePath={this.state.jDataA.STATIC_IMG_PATH}
					/>
					<MenuManager
						dataA={this.state.jDataA.MENU}
						currPageNum={this.state.currPageNum}
						callback={this.changeSection}
					/>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		//console.log(this.state.jDataA.SKILLS.SKILLS_DISCOVER.ITEMS[0].BLURB);
		//console.log(this.state.jDataA.FINAL_BLURB.EMAIL_TEXT);
		var jsx = this.compileJsx();
		
			// onClick={this.startScroller}
		return(
			<div id={'appContainer'} ref={(elem) => this.elem = elem}>
				{jsx}
			</div>
		);
	}
}

export default Base;