import React, {Component} from 'react';
import './ProjectManager.css';
import ProjectItem from './ProjectItem';


class ProjectManager extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			currNum:0,
			landscape:this.props.landscape
		};
		
		this.dataA = this.props.dataA;
		this.currY = this.props.currY;
		this.coordIndex = this.props.coordIndex;
		this.callback = this.props.callback;
		this.DELIMITER = -300;
		this.elem = null;
		this.coordsA = [];
		
		this.setCoords = this.setCoords.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		if (this.state.landscape === true)
		{
			//this.DELIMITER = -100;
			this.DELIMITER = (-window.innerHeight * .68);
		}
		else
		{
			this.DELIMITER = (-window.innerHeight * .68);
		}
		
		if (window.screen.width > 750 && this.state.landscape === false)
		{
			//this.DELIMITER = -650;
			this.DELIMITER = (-window.innerHeight * .68);
		}
		else if (window.screen.width > 1000)
		{
			this.DELIMITER = (-window.innerHeight * .68);
		}
	}
	
	componentDidMount()
	{
		//var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		//this.callback(this.coordIndex,y);
		
		var newNum = 0;
		for (var i=0;i<this.coordsA.length;i++)
		{
			if (this.currY > this.coordsA[i])
			{
				newNum++;
			}
			else
			{
				break;
			}
		}
		
		if (newNum > 0)
		{
			this.setState(
			{
				currNum:newNum
			});
		}
	}
	
	componentDidUpdate()
	{
		//var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		//this.callback(this.coordIndex,y);
	}
	
	setCoords(itemNum,y)
	{
		this.coordsA[itemNum] = y;
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.currY > this.coordsA[this.state.currNum] + this.DELIMITER)
		{
			this.setState(
			{
				currNum:this.state.currNum+1
			});
		}
		else if (this.state.currNum > 0 && newProps.currY < this.coordsA[this.state.currNum-1] + this.DELIMITER)
		{
			this.setState(
			{
				currNum:this.state.currNum-1
			});
		}
		
		if (newProps.landscape !== this.state.landscape)
		{
			if (newProps.landscape === true)
			{
				this.DELIMITER = -100;
			}
			else
			{
				this.DELIMITER = -300;
			}
			
			this.setState(
			{
				landscape:newProps.landscape
			});
		}
	}
	
	compileJsx()
	{
		var jsxA = [];
		for (var i=0;i<this.dataA.length;i++)
		{
			var isShown = i < this.state.currNum ? true : false;
			var jsx = <ProjectItem 
						dataA={this.dataA[i]}
						isShown={isShown}
						itemNum={i}
						callback={this.setCoords}
						key={'ProjectItem'+i}
					  />;
			
			jsxA.push(jsx);
		}
		
		return jsxA;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div id={'projectManagerContainer'} ref={(elem) => this.elem = elem}>
				<p className={'projectHeaderText'}>
					{this.dataA.HEADER}
				</p>
				{jsx}
			</div>
		);
	}
}

export default ProjectManager;