import React, {Component} from 'react';
import './PreLoader.css';




class PreLoader extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:true,
			className:''
		};
		
		this.scrollTopCallback = this.props.scrollTopCallback;
		this.callback = this.props.callback;
		
		this.loadingTimeout = null;
		this.removalTimeout = null;
		this.preloader = this.preloader.bind(this);
		this.removal = this.removal.bind(this);
		
		this.elem = null;
		this.starElem = null;
		
		
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		
	}
	
	componentDidMount()
	{
		this.starElem.style.background = 'url(./img/preloader/starLogoGif.gif) no-repeat';
		
		if (window.screen.width < 500) //&& this.state.landscape === false
		{
			this.starElem.style.backgroundSize = '150px 150px';
		}
		
		
		
		this.loadingTimeout = setTimeout(this.preloader,3000);
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		/*
		if (newProps.isShown !== this.state.isShown)
		{
			if (newProps.isShown)
			{
				this.show();
			}
			else
			{
				this.hide();
			}
		}
		*/
		
		/*if (newProps.title !== this.state.title)
		{
			this.setState(
			{
				title:newProps.title
			});
		}*/
	}
	
	preloader()
	{
		//var container = document.getElementById('reactContainer');
		//console.log(container.getBoundingClientRect().height);
		
		this.scrollTopCallback(0);
		
		this.elem.style.opacity = 0;
		this.removalTimeout = setTimeout(this.removal,505);
		
		this.loadingTimeout = null;
		
	}
	
	removal()
	{
		this.elem.style.zIndex = -10;
		this.callback();
	}
	
	compileJsx()
	{
		var jsx = <div className={'spinningStar'} ref={(elem) => this.starElem = elem}>
					
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div className={'preLoaderContainer'} ref={(elem) => this.elem = elem}>
				{jsx}
			</div>
		);
	}
}

export default PreLoader;