import React, {Component} from 'react';
import './UXBase.css';
//import data from './data/json.json';

import Introduction from '../utilities/introduction/Introduction';
import CaseStudies from './views/casestudies/CaseStudies';
//import RandomBlurb from './views/randomblurb/RandomBlurb';


class UXBase extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			dataA:this.props.dataA,
			currY:0,
			loaded:false,
			landscape:false
		};
		
		this.coordIndex = this.props.coordIndex;
		this.callback = this.props.callback;
		
		this.elem = null;
		
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		
	}
	
	componentDidMount()
	{
		var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		this.callback(this.coordIndex,y);
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.currY !== this.state.currY)
		{
			this.setState(
			{
				currY:newProps.currY
			});
		}
		
		
		if (newProps.loaded !== this.state.loaded)
		{
			var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
			this.callback(this.coordIndex,y);
			
			this.setState(
			{
				loaded:newProps.loaded
			});
		}
		
	}
	
	compileJsx()
	{
		var jsx = <div>
					<Introduction 
						coordIndex={0}
						dataA={this.state.dataA.INTRODUCTION}
						currY={this.state.currY}
						loaded={this.state.loaded}
					/>
					<CaseStudies 
						coordIndex={1}
						dataA={this.state.dataA.CASE_STUDIES}
						currY={this.state.currY}
					/>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		//console.log(this.state.jDataA.SKILLS.SKILLS_DISCOVER.ITEMS[0].BLURB);
		//console.log(this.state.jDataA.FINAL_BLURB.EMAIL_TEXT);
		var jsx = this.compileJsx();
		
			// onClick={this.startScroller}
		return(
			<div id={'uxBaseContainer'} ref={(elem) => this.elem = elem}>
				{jsx}
			</div>
		);
	}
}

export default UXBase;