import React, {Component} from 'react';
import './AboutBase.css';
//import data from './data/json.json';

import Introduction from '../utilities/introduction/Introduction';
import Interests from './views/interests/Interests';
//import Process from './Process';
//import CaseStudies from './CaseStudies';
//import RandomBlurb from './RandomBlurb';
//import RandomBlurbHTML from './RandomBlurbHTML';

//import ProcessManager from './views/process/ProcessManager';
//import SkillsManager from './views/skills/SkillsManager';
//import ProjectManager from './views/projects/ProjectManager';
//import MenuManager from './views/menu/MenuManager';


class AboutBase extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			dataA:this.props.dataA,
			currY:0,
			elemY:0,
			loaded:false,
			landscape:false
		};
		
		
		this.coordIndex = this.props.coordIndex;
		this.callback = this.props.callback;
		
		
		this.coordsA = [];
		
		this.elem = null;
		//this.elemY = null;
		
		
		this.setElemY = this.setElemY.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		
	}
	
	componentDidMount()
	{
		var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		this.callback(this.coordIndex,y);
		
		
		
		
		
		//var elemY = (this.elem.getBoundingClientRect().top - 500) + window.scrollY;
		
		//this.setElemY(elemY);
		
		/*
		var elemTop = (window.getComputedStyle?
    window.getComputedStyle(this.elem, null).getPropertyValue('top') :
    this.elem.currentStyle? this.elem.currentStyle.top : '0');
		*/
		
		
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.currY !== this.state.currY)
		{
			this.setState(
			{
				currY:newProps.currY
			});
		}
		
		
		if (newProps.loaded !== this.state.loaded)
		{
			var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
			this.callback(this.coordIndex,y);
			
			this.setState(
			{
				loaded:newProps.loaded
			});
		}
		
	}
	
	setElemY(y)
	{
		this.setState({
			elemY:y
		});
		
		//console.log(y);
	}
	
	
	
	/*
	
	
					<CaseStudies 
						coordIndex={1}
						dataA={this.state.dataA.CASE_STUDIES}
						currY={this.state.currY}
						callback={this.setCoordsA}
						landscape={this.state.landscape}
					/>
					<Process 
						coordIndex={2}
						dataA={this.state.dataA.PROCESS}
						currY={this.state.currY}
						callback={this.setCoordsA}
						landscape={this.state.landscape}
					/>
					<RandomBlurb
						dataA={this.state.dataA.RANDOM_BLURB_LINK}
						currY={this.state.currY}
					/>
	
	*/
	
	
	compileJsx()
	{
		var jsx = <div>
					<Introduction 
						coordIndex={0}
						dataA={this.state.dataA.INTRODUCTION}
						currY={this.state.currY}
						loaded={this.state.loaded}
					/>
					<Interests 
						coordIndex={1}
						dataA={this.state.dataA.INTERESTS}
						currY={this.state.currY}
						callback={this.setCoordsA}
					/>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		//console.log(this.state.jDataA.SKILLS.SKILLS_DISCOVER.ITEMS[0].BLURB);
		//console.log(this.state.jDataA.FINAL_BLURB.EMAIL_TEXT);
		var jsx = this.compileJsx();
		
			// onClick={this.startScroller}
		return(
			<div id={'aboutBaseBaseContainer'} ref={(elem) => this.elem = elem}>
				{jsx}
			</div>
		);
	}
}

export default AboutBase;