import React, {Component} from 'react';
import './Menu.css';
import MenuItem from './MenuItem';


class Menu extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			currShownNum:1,
			currPageNum:this.props.currPageNum
		};
		
		this.dataA = this.props.dataA;
		//this.CURR_PAGE_NUM = this.props.currPageNum;
		this.callRemoval = this.props.callRemoval;
		this.sectionChosenCallback = this.props.sectionChosenCallback;
		this.removalCallback = this.props.removalCallback;
		this.timer = null;
		this.timeout = null;
		this.shownItemsA = [];
		this.OPACITY_BASE = .7;
		this.OPACITY_INC = .07;
		
				//this.CURR_PAGE_NUM = 1;
		
		this.showItem = this.showItem.bind(this);
		this.hideItem = this.hideItem.bind(this);
		this.sectionChosen = this.sectionChosen.bind(this);
		this.removal = this.removal.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		for (var i=0;i<this.dataA.length;i++)
		{
			var boo = i === 0 ? true : false;
			this.shownItemsA.push(boo);
		}
		
		//console.log(this.shownItemsA);
	}
	
	componentDidMount()
	{
		this.timer = setInterval(this.showItem,50);
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.callRemoval !== this.callRemoval)
		{
			this.callRemoval = newProps.callRemoval;
			clearInterval(this.timer);
			this.timer = setInterval(this.hideItem,50);
		}
		
		
		if (newProps.currPageNum !== this.state.currPageNum)
		{
			this.setState({
				currPageNum:newProps.currPageNum
			});
		}
	}
	
	showItem()
	{
		var num = this.state.currShownNum;
		
		for (var i=0;i<this.shownItemsA.length;i++)
		{
			if (this.shownItemsA[i] === false)
			{
				this.shownItemsA[i] = true;
				num++;
				break;
			}
		}
		
		if (num >= this.dataA.length)
		{
			clearInterval(this.timer);
			this.timer = null;
		}
		
		this.setState(
		{
			currShownNum:num
		});
	}
	
	hideItem()
	{
		var num = this.state.currShownNum;
		
		for (var i=num;i>-1;i--)
		{
			if (this.shownItemsA[i] === true)
			{
				this.shownItemsA[i] = false;
				num--;
				break;
			}
		}
		
		if (num <= 0)
		{
			clearInterval(this.timer);
			this.timer = null;
			this.timeout = setTimeout(this.removal,301);
		}
		
		this.setState(
		{
			currShownNum:num
		});
	}
	
	sectionChosen(indexNum)
	{
		//this.CURR_PAGE_NUM = indexNum;
		
		this.setState(
		{
			currPageNum:indexNum
		});
		
		this.sectionChosenCallback(indexNum);
	}
	
	removal()
	{
		this.removalCallback();
	}
	
	compileJsx()
	{
		var jsxA = [];
		var opac = this.OPACITY_BASE;
		if (window.screen.width > 750) //&& this.state.landscape === false
		{
			opac = 1;
		}
		
		for (var i=0;i<this.dataA.length;i++)
		{
			
			
			var jsx = <MenuItem
						indexNum={i}
						isShown={this.shownItemsA[i]}
						currPageNum={this.state.currPageNum}
						opacity={opac}
						text={this.dataA[i]}
						callback={this.sectionChosen}
						key={'MenuItem'+i}
					 />;
			
			opac += this.OPACITY_INC;
			jsxA.push(jsx);
		}
		
		return jsxA;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div id={'menuContainer'}>
				{jsx}
			</div>
		);
	}
}

export default Menu;