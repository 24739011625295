import React, {Component} from 'react';
import './MenuItem.css';


class MenuItem extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:this.props.isShown,
			currPageNum:this.props.currPageNum
		};
		
		this.indexNum = this.props.indexNum;
		//this.currPageNum = this.props.currPageNum;
		this.opacity = this.props.opacity;
		this.text = this.props.text;
		this.callback = this.props.callback;
		this.timeout = null;
		this.elem = null;
		
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.mOver = this.mOver.bind(this);
		this.mOut = this.mOut.bind(this);
		this.clicked = this.clicked.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		
	}
	
	componentDidMount()
	{
		if (this.state.isShown)
		{
			this.timeout = setTimeout(this.show,10);
		}
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		//this.currPageNum = newProps.currPageNum;
		
		//console.log(newProps.currPageNum);
		
		if (newProps.isShown !== this.state.isShown)
		{
			if (newProps.isShown)
			{
				this.show();
			}
			else
			{
				this.hide();
			}
		}
		
		if (newProps.currPageNum !== this.state.currPageNum)
		{
			
			
			// JUST ADDED FOR DESKTOP, HOPEFULLY DOESN'T FUCK UP MOBILE:
			if (window.screen.width > 750) // && this.state.landscape === false
			{
				if (this.indexNum === newProps.currPageNum)
				{
					this.elem.style.backgroundColor="rgba(128, 128, 128, 1)";
				}
				else
				{
					this.elem.style.backgroundColor="rgba(0, 0, 0, 1)";
				}
			}
			
			
			
			
			
			
			this.setState({
				currPageNum:newProps.currPageNum
			});
		}
	}
	
	show()
	{
		this.elem.style.opacity = Number(this.opacity);
		
		if (this.indexNum === this.state.currPageNum)
		{
			this.elem.style.backgroundColor="rgba(128, 128, 128, 1)";
		}
		//this.elem.style.backgroundColor="rgba(0, 0, 0, 0.5)";
		
		this.setState(
		{
			isShown:true
		});
	}
	
	hide()
	{
		this.elem.style.opacity = 0;
		//this.elem.style.backgroundColor="rgba(0, 0, 0, 0)";
		
		this.setState(
		{
			isShown:false
		});
	}
	
	mOver()
	{
		this.elem.style.backgroundColor="rgba(128, 128, 128, 1)";
	}
	
	mOut()
	{
		if (this.indexNum !== this.state.currPageNum)
		{
			this.elem.style.backgroundColor="rgba(0, 0, 0, 1)";
		}
		
	}
	
	clicked()
	{
		this.elem.style.backgroundColor="rgba(128, 128, 128, 1)";
		
		this.callback(this.indexNum);
	}
	
	shouldComponentUpdate(newProps,newState)
	{
		if (newProps.isShown !== this.state.isShown)
		{
			return true;
		}
		return false;
	}
	
	compileJsx()
	{
		/*var letter = this.text.substring(0,1);
					<div className={'menuLetter'}>
						{letter}
					</div>*/
		
		var jsx = <div className={'menuItem'}>
					<div className={'menuText'}>
						{this.text}
					</div>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER MENU ITEM');
		var jsx = this.compileJsx();
		
		return(
			<div className={'menuItemContainer'} ref={(elem) => this.elem = elem} onClick={this.clicked} onMouseOver={this.mOver} onMouseOut={this.mOut}>
				{jsx}
			</div>
		);
	}
}

export default MenuItem;