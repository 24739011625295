import React, {Component} from 'react';
import './Burger.css';


class Burger extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			
		};
		
		this.callback = this.props.callback;
		
		this.clicked = this.clicked.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		
	}
	
	componentDidMount()
	{
		
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		
	}
	
	clicked()
	{
		this.callback();
	}
	
	compileJsx()
	{
		var jsx = <div id={'burger'}>
					<div className={'burgerLine'}></div>
					<div className={'burgerLine'}></div>
					<div className={'burgerLine'}></div>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div id={'burgerContainer'} onClick={this.clicked}>
				{jsx}
			</div>
		);
	}
}

export default Burger;