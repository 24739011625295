import React, {Component} from 'react';
import './InterestsItem.css';
import InterestsImage from './InterestsImage';
import InterestsBlurb from './InterestsBlurb';


class InterestsItem extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:this.props.isShown
		};
		
		this.dataA = this.props.dataA;
		this.elem = null;
		this.itemNum = this.props.itemNum;
		this.callback = this.props.callback;
		
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	/*componentWillMount()
	{
		
	}*/
	
	componentDidMount()
	{
		//var h = window.getComputedStyle(this.elem).height;
		var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		//console.log(y);
		this.callback(this.itemNum,y);
	}
	
	componentDidUpdate()
	{
		var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		this.callback(this.itemNum,y);
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.isShown !== this.state.isShown)
		{
			if (newProps.isShown)
			{
				this.show();
			}
			else
			{
				this.hide();
			}
		}
	}
	
	show()
	{
		this.setState(
		{
			isShown:true
		});
	}
	
	hide()
	{
		this.setState(
		{
			isShown:false
		});
	}
	
	compileJsx()
	{
		var jsx = <div className={'interestsItem'}>
					<InterestsImage 
						imagePath={this.dataA.IMAGE_PATH} 
						isShown={this.state.isShown}
					/>
					<InterestsBlurb 
						title={this.dataA.TITLE} 
						blurb={this.dataA.BLURB} 
						isShown={this.state.isShown}
						linkDetails = {this.dataA.LINK_DETAILS}
					/>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div className={'interestsItemContainer'} ref={(elem) => this.elem = elem}>
				{jsx}
			</div>
		);
	}
}

export default InterestsItem;