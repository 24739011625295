import React, {Component} from 'react';
import './ProjectBlurb.css';

import ButtonManager from '../../../utilities/button/ButtonManager';


class ProjectBlurb extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:this.props.isShown,
			className:'projectBlurb'
		};
		
		this.dataA = this.props.dataA;
		
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	/*componentWillMount()
	{
		
	}*/
	
	componentDidMount()
	{
		/*
		//var h = window.getComputedStyle(this.elem).height;
		var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		//console.log(y);
		this.callback(this.itemNum,y);
		*/
		
		//this.elem.style.backgroundImage = 'url(./'+this.imagePath+')';
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.isShown !== this.state.isShown)
		{
			if (newProps.isShown)
			{
				this.show();
			}
			else
			{
				this.hide();
			}
		}
	}
	
	show()
	{
		this.setState(
		{
			isShown:true,
			className:'projectBlurb show'
		});
	}
	
	hide()
	{
		this.setState(
		{
			isShown:false,
			className:'projectBlurb'
		});
	}
	
	compileJsx()
	{
		var i = 0;
		
		var paragraphSplit = this.dataA.BLURB.split('\n');
		var paragraphJsx = [];
		for (i=0;i<paragraphSplit.length;i++)
		{
			var pJsx = <p key={'projectBlurbP'+i}>
						{paragraphSplit[i]}<br/>
					   </p>;
			paragraphJsx.push(pJsx);
		}
		
		var detailsJsx = [];
		for (i=0;i<this.dataA.DETAILS_A.length;i++)
		{
			var dJsx;
			if (i === 1)
			{
				dJsx = <p key={'detailsA'+i}>
						<a href={'http://'+this.dataA.LIVE_LINK} target={'_blank'} key={this.dataA.TITLE}>{this.dataA.DETAILS_A[i]}</a>
					   </p>
			}
			else if (i === 2)
			{
				dJsx = <p key={'detailsA'+i}>
						<a href={'http://'+this.dataA.GIT_LINK} target={'_blank'} key={this.dataA.TITLE}>{this.dataA.DETAILS_A[i]}</a>
					   </p>
			}
			else
			{
				dJsx = <p key={'detailsA'+i}>
						{this.dataA.DETAILS_A[i]}
					  </p>;
			}
			
			detailsJsx.push(dJsx);
		}
		
		var jsx = <div>
					<div className={this.state.className}>
						<span className={'projectTitle'}>
							{this.dataA.TITLE}
						</span>
						
						<div>
							{paragraphJsx}
							<br/>
							{detailsJsx}
						</div>
						{this.dataA.BUTTONS_A ? 
						<ButtonManager 
							isShown={this.state.isShown}
							buttonsA={this.dataA.BUTTONS_A}
						/> : null}
					</div>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div className={'projectBlurbContainer'}>
				{jsx}
			</div>
		);
	}
}

export default ProjectBlurb;