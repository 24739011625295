import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
//import './index.css';
import Base from './Base';


var container = document.getElementById('reactContainer');
const root = createRoot(container);

var xhttpR = new XMLHttpRequest();
var jDataA = {};

xhttpR.onreadystatechange = function()
{
	if (this.readyState === 4 && this.status === 200)
	{
		jDataA = JSON.parse(xhttpR.responseText);
		//console.log(data);
		
		root.render(
			<Base jDataA={jDataA}/>
		);
	}
}

xhttpR.open('GET','./data/json.json',true);
xhttpR.send();