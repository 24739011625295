import React, {Component} from 'react';
import './Static.css';




class Static extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:true,
			className:''
		};
		
		this.imagePath = this.props.imagePath;
		
		this.elem = null;
		
		
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	UNSAFE_componentWillMount()
	{
		
	}
	
	componentDidMount()
	{
		var sizer = 'LG';
		
		if (window.screen.width > 750) //&& this.state.landscape === false
		{
			//this.DELIMITER = -650;
		}
		else if (window.screen.width > 1000)
		{
			//this.DELIMITER = -350;
		}
		else
		{
			sizer = 'SM';
		}
		
		var sizeSplitter = this.imagePath.split('SIZE');
		
		this.elem.style.background = 'url(./'+sizeSplitter[0]+sizer+sizeSplitter[1]+') no-repeat';
		
		this.elem.style.backgroundSize = 'cover';
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		/*
		if (newProps.isShown !== this.state.isShown)
		{
			if (newProps.isShown)
			{
				this.show();
			}
			else
			{
				this.hide();
			}
		}
		*/
		
		/*if (newProps.title !== this.state.title)
		{
			this.setState(
			{
				title:newProps.title
			});
		}*/
	}
	
	compileJsx()
	{
		var jsx = <div>
					
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div className={'staticContainer'} ref={(elem) => this.elem = elem}>
				{jsx}
			</div>
		);
	}
}

export default Static;