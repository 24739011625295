import React, {Component} from 'react';
import './Introduction.css';
import IntroductionImage from './IntroductionImage';
import IntroductionTitle from './IntroductionTitle';
import IntroductionBlurb from './IntroductionBlurb';
//import IntroductionMover from './IntroductionMover';


class Introduction extends Component
{
	constructor(props,context)
	{
		super(props,context);
		
		this.state={
			isShown:this.props.isShown,
			currY:this.props.currY,
			loaded:false
		};
		
		this.dataA = this.props.dataA;
		this.coordIndex = this.props.coordIndex;
		this.callback = this.props.callback;
		this.elem = null;
		this.timeout = null;
		
		this.show = this.show.bind(this);
		this.compileJsx = this.compileJsx.bind(this);
	}
	
	/*componentWillMount()
	{
		
	}*/
	
	componentDidMount()
	{
		var y = (this.elem.getBoundingClientRect().top) + window.scrollY;
		//this.callback(this.coordIndex,y);
		
		this.timeout = setTimeout(this.show,10);
	}
	
	UNSAFE_componentWillReceiveProps(newProps)
	{
		if (newProps.currY !== this.state.currY)
		{
			this.setState(
			{
				currY:newProps.currY
			});
		}
		
		
		if (newProps.loaded !== this.state.loaded)
		{
			this.setState(
			{
				loaded:newProps.loaded
			});
		}
	}
	
	show()
	{
		this.setState(
		{
			isShown:true
		});
	}
	
	/*
	
					<IntroductionMover
						currY={this.state.currY}
						imagePath={this.dataA.MOVER_PATH} 
					/>
	*/
	
	compileJsx()
	{
		var jsx = <div className={'introduction'}>
					<IntroductionImage 
						currY={this.state.currY}
						imagePath={this.dataA.IMAGE_PATH} 
						isShown={this.state.isShown}
					/>
					<IntroductionTitle
						currY={this.state.currY}
						name={this.dataA.NAME} 
						title={this.dataA.TITLE} 
						isShown={this.state.isShown}
						loaded={this.state.loaded}
					/>
					<IntroductionBlurb
						dataA={[this.dataA.BLURB_TITLE,this.dataA.BLURB]} 
						isShown={this.state.isShown}
					/>
				  </div>
		
		return jsx;
	}
	
	render()
	{
		//console.log('RENDER BASE');
		var jsx = this.compileJsx();
		
		return(
			<div className={'introductionContainer'} ref={(elem) => this.elem = elem}>
				{jsx}
			</div>
		);
	}
}

export default Introduction;